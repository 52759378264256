import React from 'react'
import { SessionProvider } from 'next-auth/react'
import App, { AppContext, AppProps } from 'next/app'
import 'styles/global.scss'
import { ErrorBoundary } from 'utils/bugsnag'
import { AppHead } from 'components/AppHead'
import { AppScripts } from 'components/AppScripts'
import { ConxSdkProvider } from 'modules/ConxSdkProvider'
import { SdkLogsProvider } from 'modules/SdkLogsProvider'
import { AuthenticationProvider } from 'modules/AuthenticationProvider'
import { AnalyticsProvider } from 'modules/AnalyticsProvider'
import { DeviceConnectionProvider } from 'modules/DeviceConnectionProvider'
import { AppProvider } from 'modules/AppProvider'
import { FeedbackProvider } from 'modules/FeedbackProvider'
import { ReoverlayModal } from 'modules/ReoverlayModal'
import { Layout } from 'modules/Layout'
import { useCustomConsole } from 'modules/CustomConsole'
import { DevTools } from 'modules/DevTools'
import { AcceptedCountryCodes } from 'types/regions'

interface ConxWebProps extends AppProps {
  appHeaders: {
    appHost: string
    userRegion: string
  }
}

const ConxWeb = ({
  Component,
  appHeaders,
  pageProps: { session, ...pageProps },
}: ConxWebProps) => {
  useCustomConsole()

  return (
    <ErrorBoundary>
      <AppHead />
      <AppScripts />
      <SessionProvider session={session}>
        <ConxSdkProvider {...appHeaders}>
          <SdkLogsProvider>
            <AnalyticsProvider>
              <AuthenticationProvider>
                <DeviceConnectionProvider>
                  <AppProvider>
                    <FeedbackProvider>
                      <ReoverlayModal />
                      <DevTools>
                        <Layout>
                          <Component {...pageProps} />
                        </Layout>
                      </DevTools>
                    </FeedbackProvider>
                  </AppProvider>
                </DeviceConnectionProvider>
              </AuthenticationProvider>
            </AnalyticsProvider>
          </SdkLogsProvider>
        </ConxSdkProvider>
      </SessionProvider>
    </ErrorBoundary>
  )
}

ConxWeb.getInitialProps = async(appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)

  const { ctx } = appContext
  const headers = ctx.req ? ctx.req.headers : {}
  const userRegion = headers['x-vercel-ip-country'] || AcceptedCountryCodes.GB
  const appHost = headers.host || 'app.juul.com'

  return {
    appHeaders: { appHost, userRegion },
    ...appProps,
  }
}

export default ConxWeb
