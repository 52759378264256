import React, { FC, PropsWithChildren, useState } from 'react'
import Routes from 'types/routes'
import { useSdkLogs } from 'modules/SdkLogsProvider'
import { useConsoleLogs } from 'modules/CustomConsole'
import { useSetupPlaywright } from 'modules/PlaywrightDeveloperTools'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { useRemoteConfigManager } from 'modules/RemoteConfigManager'
import { getIsPreProdEnvironment } from 'utils/app'
import { SlidingPanel } from './components/SlidingPanel'
import { ButtonStack } from './components/ButtonStack'

export const DevTools: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const isPreProdEnvironment = getIsPreProdEnvironment()
  const { userAgent } = useConxSdk()
  const { openSdkLogsModal } = useSdkLogs()
  const { openConsoleLogsModal } = useConsoleLogs()
  const { openRemoteConfigManagerModal } = useRemoteConfigManager()
  const { openPlaywrightModal } = useSetupPlaywright()

  return (
    <>
      {children}
      {isPreProdEnvironment && (
        <SlidingPanel
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tabLabel='Tools'
        >
          <ButtonStack
            onClickOption={() => setIsOpen(false)}
            options={[
              {
                label: 'SDK Logs',
                onClick: openSdkLogsModal,
              },
              {
                label: 'Remote Config Manager',
                onClick: openRemoteConfigManagerModal,
              },
              {
                disabled: !userAgent?.isBluefy,
                label: 'Custom Console',
                onClick: openConsoleLogsModal,
              },
              {
                disabled: true,
                label: 'Mock Usage (TBD)',
              },
              {
                href: Routes.Diagnostics,
                label: 'BLE Diagnostics',
                local: true,
              },
              {
                href: Routes.FeedbackDeveloperTools,
                label: 'Feedback Tools',
                local: true,
              },
              {
                label: 'Playwright',
                onClick: openPlaywrightModal,
              },
            ]}/>
        </SlidingPanel>
      )}
    </>
  )
}
